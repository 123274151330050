<template>
  <div>

    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div
        class="flex xs12"
        :class="{'sm8': hasOtherContacts || hasAvatar}"
      >
        <va-card :title="$t('users.view')">
          <actions
            slot="actions"
            crud-links="users"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.name')">
            {{ user.name }}
          </text-list>
          <template v-if="hasGroups">
            <text-list
              v-for="group of user.groups"
              :key="group.id"
              :condition="currentUser.can('Groups', 'view')"
              :title="$t('tables.headings.groups')"
              :label="$t(`groups.${group.name}`)"npm
              :to="{ name: 'groupsView', params: {id: group.id}}"
            />
          </template>
          <text-list
            v-if="user.dcpi_role"
            :condition="currentUser.can('DcpiRoles', 'view')"
            :title="$t('tables.headings.dcpi_role')"
            :label="$t(user.dcpi_role.name)"
            :to="{ name: 'rolesView', params: {id: user.dcpi_role.id}}"
          />
          <text-list
            v-if="user.team"
            :condition="currentUser.can('Teams', 'view')"
            :title="$t('tables.headings.team')"
            :label="user.team.code"
            :to="{ name: 'teamsView', params: {id: user.team.id}}"
          />
          <text-list
            v-if="user.country"
            :condition="currentUser.can('Countries', 'view')"
            :title="$t('tables.headings.country')"
            :label="$t(user.country.name)"
            :to="{ name: 'countriesView', params: {id: user.country.id}}"
          />
          <text-list
            v-if="user.district"
            :condition="currentUser.can('Districts', 'view')"
            :title="$t('tables.headings.district')"
            :label="$t(user.district.name)"
            :to="{ name: 'districtsView', params: {id: user.district.id}}"
          />
          <text-list :title="$t('tables.headings.email')">
            <a
              class="table-link"
              :href="'mailto:' + user.email"
            >
              {{ user.email }}
            </a>
          </text-list>
          <text-list :title="$t('tables.headings.birthdate')">
            {{ user.birthdate | date }}
          </text-list>
          <text-list
            v-show="user.updated_at"
            :title="$t('tables.headings.last_modified')"
          >
            {{ user.updated_at | date }}
          </text-list>
        </va-card>
      </div>
      <div
        v-if="hasOtherContacts || hasAvatar"
        class="flex xs12 sm4"
      >
        <div class="row">
          <div class="flex xs12" v-if="hasAvatar">
            <label>{{ $t('users.tabs.avatar.preview') }}</label>
            <img :src="getAvatarPath(user.avatar_path)" style="width: 100%;" />
          </div>
          <div class="flex xs12" v-if="hasOtherContacts">
            <va-list
              fit
              class="mb-2"
            >
              <va-list-label>
                {{ $t('users.contacts.label') }}
              </va-list-label>

              <template v-for="(contact, i) of user.user_contacts">
                <va-item
                  :key="'item' + contact.id"
                  clickable
                  @click="getContactLink(contact)"
                >
                  <va-item-section avatar>
                    <va-icon :name="`fa fa-${getContactIcon(contact)}`" />
                  </va-item-section>

                  <va-item-section>
                    <va-item-label>
                      {{ $t(contact.type.name) }}
                    </va-item-label>

                    <va-item-label caption>
                      {{ contact.value }}
                    </va-item-label>
                  </va-item-section>
                </va-item>

                <va-list-separator
                  v-if="i < user.user_contacts.length - 1"
                  :key="'separator' + contact.id"
                />
              </template>
            </va-list>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row"
      v-if="dcpiTrainings.length > 0"
    >
      <div class="flex xs12">
        <va-card :title="$t('users.trainings')">
          <trainings-table
            :user="user"
            :trainings="dcpiTrainings"
            :loading="loading"
          />
        </va-card>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const TrainingsTable = () => import(/* webpackPrefetch: true */ './Trainings')
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'users-view',
  components: {
    Actions,
    TextList,
    TrainingsTable,
  },
  data () {
    return {
      error: false,
      loading: false,
      dcpiTrainings: [],
      user: {},
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  created () {
    this.updateData()
  },
  computed: {
    ...mapGetters(['currentUser']),
    hasGroups () {
      return this.user.groups && this.user.groups.length > 0
    },
    hasOtherContacts () {
      return this.user && Array.isArray(this.user.user_contacts) && this.user.user_contacts.length > 0
    },
    hasAvatar () {
      return this.user && this.user.avatar_path
    },
    contactTypes () {
      const types = [
        {
          name: 'users.contacts.types.email',
          value: 0,
        },
        {
          name: 'users.contacts.types.phone',
          value: 1,
        },
        {
          name: 'users.contacts.types.whatsapp',
          value: 2,
        },
        {
          name: 'users.contacts.types.facebook',
          value: 3,
        },
        {
          name: 'users.contacts.types.twitter',
          value: 4,
        },
      ]

      return types
    },
  },
  methods: {
    fixData (data) {
      const noDef = this.$t('tables.undefined')

      if (!data.dcpi_role) {
        data.dcpi_role = {
          name: noDef,
        }
      }
      for (const contact of data.user_contacts) {
        contact.type = this.contactTypes.find(c => c.value === contact.type)
      }

      return data
    },
    getContactIcon (contact) {
      let icon = ''
      const type = contact.type.value

      switch (type) {
        case 0: icon = 'envelope'; break
        case 1: icon = 'phone'; break
        case 2: icon = 'whatsapp'; break
        case 3: icon = 'facebook'; break
        case 4: icon = 'twitter'; break
      }

      return icon
    },
    getContactLink (contact) {
      let link = ''
      const type = contact.type.value
      const value = contact.value

      switch (type) {
        case 0: link = `mailto:${value}`; break
        case 1: link = `tel:${value}`; break
        case 2: link = `https://wa.me/${value}`; break
        case 3: link = `https://facebook.com/${value}`; break
        case 4: link = `https://twitter.com/${value}`; break
      }

      window.open(link, '_blank')
    },
    getAvatarPath (path) {
      let base = ''
      if (process.env.NODE_ENV === 'development') {
        base = this.$http.defaults.baseURL.match(/^https?:\/\/[^/]+/g)[0]
      }
      return base + path
    },
    routeBuilder (id) {
      return `users/${id}?with_trainings=true`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const userId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(userId))
      } catch (err) {
        // console.log('Error fetching user data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.user = this.fixData(u.data.data)
      if (Array.isArray(this.user.dcpi_trainings)) {
        this.dcpiTrainings = this.user.dcpi_trainings
      }
    },
  },
}
</script>
